import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from "i18next-http-backend";
import { initReactI18next } from 'react-i18next';

export const supportedLngs = {
    de: "Deutsch",
    en: "English",
};

i18n
    // to detect user language
    .use(HttpApi)
    .use(LanguageDetector)
    // to pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    .init({
        fallbackLng: 'de',
        debug: false,
        supportedLngs: Object.keys(supportedLngs),
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });

export default i18n;