import { CustomLink } from "data/types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Logo from "shared/Logo/Logo";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
];

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-8 lg:py-8 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
      <div className="grid col-span-1 md:col-span-1 md:flex items-center justify-center mt-2 mb-2 md:space-x-4">
        <Link to={"https://www.icnab.de/pages/agb.php"} target="_blank" className="text-primary-6000 dark:text-primary-400 hover:underline">{t("page.signup.dataPrivacy.terms", "Terms and Conditions")}</Link>
        <Link to={"https://www.icnab.de/pages/datenschutz.php"} target="_blank" className="text-primary-6000 dark:text-primary-400 hover:underline">{t("page.signup.dataPrivacy.privacy", "Privacy Policy")}</Link>
      </div>
    </div>
  );
};

export default Footer;
