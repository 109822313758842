import FooterNav from "components/FooterNav";
import ProtectedRoute from "components/ProtectedRoute";
import Page404 from "containers/404/Page404";
import PageAbout from "containers/About/PageAbout";
import AccountPage from "containers/Account/AccountPage";
import AccountPass from "containers/Account/AccountPass";
import AdminApplicationReviewPage from "containers/Application/ApplicationReview/AdminApplicationReviewPage";
import LandlordApplicationReviewPage from "containers/Application/ApplicationReview/LandlordApplicationReviewPage";
import ApplicationPage from "containers/Application/CreateApplication/ApplicationPage";
import ApplicationSuccessPage from "containers/Application/CreateApplication/ApplicationSuccessPage";
import MyApplicationsPage from "containers/Application/MyApplications/MyApplicationsPage";
import PageContact from "containers/Contact/PageContact";
import FinishRegistrationPage from "containers/FinishRegistration/FinishRegistrationPage";
import RegistrationFinishedPage from "containers/FinishRegistration/RegistrationFinishedPage";
import ListingStayDetailPage from "containers/Listing/ListingDetail/ListingDetailPage";
import PageManageListing from "containers/Listing/ListingManage/PageManageListing";
import SearchPage from "containers/Listing/ListingSearch/SearchPage";
import MyListings from "containers/Listing/MyListings/MyListingsPage";
import PageLogin from "containers/Login/PageLogin";
import PageLandlordSignUp from "containers/SignUp/PageLandlordSignUp";
import PageSignUpSuccess from "containers/SignUp/PageSignUpSuccess";
import PageStudentSignUp from "containers/SignUp/PageStudentSignUp";
import SiteHeader from "containers/SiteHeader";
import ForgotPasswordPage from "containers/User/Password/ForgotPasswordPage";
import ForgotPasswordSuccessPage from "containers/User/Password/ForgotPasswordSuccessPage";
import ResetPasswordPage from "containers/User/Password/ResetPasswordPage";
import UserRegistrationRequests from "containers/User/RegistrationRequests/RegistrationRequestsPage";
import UserListPage from "containers/User/UserList/UserListPage";
import useWindowSize from "hooks/useWindowResize";
import { UserRole } from "models/users";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "shared/Footer/Footer";
import ScrollToTop from "./ScrollToTop";
import { Page } from "./types";
import StatsPage from "containers/Stats/RegistrationRequests/StatsPage";

export const pages: Page[] = [
  { path: "/", exact: true, component: SearchPage },
  { path: "/account", component: AccountPage, protected: true },
  { path: "/change-password", component: AccountPass, protected: true },
  //
  { path: "/users", component: UserListPage, protected: true, allowedRoles: [UserRole.ADMIN] },
  { path: "/registration-requests", component: UserRegistrationRequests, protected: true, allowedRoles: [UserRole.ADMIN] },
  { path: "/stats", component: StatsPage, protected: true, allowedRoles: [UserRole.ADMIN] },
  //
  { path: "/listing/:id", component: ListingStayDetailPage },
  { path: "/manage-listing/:id", component: PageManageListing, protected: true, allowedRoles: [UserRole.LANDLORD] },
  { path: "/add-listing", component: PageManageListing, protected: true, allowedRoles: [UserRole.LANDLORD] },
  { path: "/my-listings", component: MyListings, protected: true, allowedRoles: [UserRole.LANDLORD] },

  //
  { path: "/application-success", component: ApplicationSuccessPage, protected: true, allowedRoles: [UserRole.STUDENT] },
  { path: "/my-applications", component: MyApplicationsPage, protected: true, allowedRoles: [UserRole.STUDENT] },
  { path: "/student-applications", component: AdminApplicationReviewPage, protected: true, allowedRoles: [UserRole.ADMIN] },
  { path: "/applications", component: LandlordApplicationReviewPage, protected: true, allowedRoles: [UserRole.LANDLORD] },
  { path: "/listing/:id/apply", component: ApplicationPage, protected: true, allowedRoles: [UserRole.STUDENT] },
  //
  //
  { path: "/signup-landlord", component: PageLandlordSignUp },
  { path: "/signup-student", component: PageStudentSignUp },
  { path: "/signup-success", component: PageSignUpSuccess },
  { path: "/login", component: PageLogin },
  { path: "/finish-registration", component: FinishRegistrationPage },
  { path: "/registration-finished", component: RegistrationFinishedPage },
  { path: "/forgot-password", component: ForgotPasswordPage },
  { path: "/forgot-password-success", component: ForgotPasswordSuccessPage },
  { path: "/reset-password", component: ResetPasswordPage },
  { path: "*", component: Page404 },
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
];

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />
      <div className="min-h-screen flex flex-col">
        <div className="flex-grow" >
          <Routes  >
            {pages.map((page) => {
              const Component = page.component;
              const isProtected = page.protected === true;
              const allowedRoles = page.allowedRoles || [];

              if (isProtected) {
                return (<>
                  <Route key={page.path} element={
                    <ProtectedRoute key={`protected_${page.path}`} loginPath="/login" allowedRoles={allowedRoles}>
                      <Component />
                    </ProtectedRoute>
                  } path={page.path} />
                </>
                );
              }
              return <Route key={page.path} element={<Component />} path={page.path} />;
            })}
          </Routes>
        </div>
        <div className="mt-8">
          <Footer />
        </div>
      </div>

      {WIN_WIDTH < 768 && <div className="mt-8"><FooterNav /></div>}

    </BrowserRouter>
  );
};

export default MyRoutes;
