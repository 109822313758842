import { BaseModel } from "./base-entity";
import { ApprovalStatus } from "./commons";
import { Document } from "./document";
import { PagingAndSortingFilter } from "./filters";
export interface LandlordRegistration {
    email: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    dateOfBirth: string;
    phoneNumber: string;
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
}

export interface AdminRegistration {
    email: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    dateOfBirth: string;
    phoneNumber: string;
}

export interface StudentRegistration {
    email: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    dateOfBirth: string;
    phoneNumber: string;
    aboutMe: string;
    proofOfStudy: File;
}

export enum UserStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    REGISTRATION_PENDING = 'REGISTRATION_PENDING',
    NEW = 'NEW'
}

export enum Gender {
    MALE = "MALE",
    FEMALE = "FEMALE",
    OTHER = "OTHER"
}

export interface User extends BaseModel {
    email: string;
    firstName: string;
    lastName: string;
    role: UserRole;
    status: UserStatus;
    gender: Gender;
    dateOfBirth: string;
    phoneNumber: string;
    aboutMe: string;
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
}

export interface UserCredentails {
    email: string;
    password: string;
}

export interface FinishRegistrationRequest {
    email: string;
    password: string;
    registrationToken: string;
}

export interface ActivationInformation {
    email: string;
    registrationToken: string;
}

export interface CheckActivationResponse {
    activationPossible: boolean;
}

export interface Token {
    token: string;
}

export interface StudentRegistrationRequest extends BaseModel {
    student: User;
    status: ApprovalStatus;
    proofOfStudyDocument: Document;
}

export interface LandlordRegistrationRequest extends BaseModel {
    landlord: User;
    status: ApprovalStatus;
}

export enum UserRole {
    ADMIN = 'ADMIN',
    LANDLORD = 'LANDLORD',
    STUDENT = 'STUDENT'
}

export interface UserFilter extends PagingAndSortingFilter {
    roles?: UserRole[];
    statuses?: UserStatus[];
}

export interface RegistrationRequestFilter extends PagingAndSortingFilter {
    statuses?: ApprovalStatus[];
}