import {
  HeartIcon,
  MagnifyingGlassIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { useAuth } from "providers/AuthProvider";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { PathName } from "routers/types";
import MenuBar from "shared/MenuBar/MenuBar";
import { UserRole } from "../models/users";

let WIN_PREV_POSITION = window.pageYOffset;

interface NavItem {
  name: string;
  link?: PathName;
  icon: any;
  localizationKey?: string;
}


const FooterNav = () => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  //
  const { currentUser } = useAuth();

  const location = useLocation();

  useEffect(() => {
    window.addEventListener("scroll", handleEvent);
  }, []);

  const handleEvent = () => {
    window.requestAnimationFrame(showHideHeaderMenu);
  };

  const showHideHeaderMenu = () => {
    // let currentScrollPos = window.pageYOffset;
    // if (!containerRef.current) return;

    // // SHOW _ HIDE MAIN MENU
    // if (currentScrollPos > WIN_PREV_POSITION) {
    //   if (
    //     isInViewport(containerRef.current) &&
    //     currentScrollPos - WIN_PREV_POSITION < 80
    //   ) {
    //     return;
    //   }

    //   containerRef.current.classList.add("FooterNav--hide");
    // } else {
    //   if (
    //     !isInViewport(containerRef.current) &&
    //     WIN_PREV_POSITION - currentScrollPos < 80
    //   ) {
    //     return;
    //   }
    //   containerRef.current.classList.remove("FooterNav--hide");
    // }

    // WIN_PREV_POSITION = currentScrollPos;
  };

  return (
    <div
      ref={containerRef}
      className="FooterNav p-2 bg-white dark:bg-neutral-800 fixed top-auto bottom-0 inset-x-0 z-30 border-t border-neutral-300 dark:border-neutral-700 
      transition-transform duration-300 ease-in-out"
    >
      <div className="w-full max-w-lg flex justify-around mx-auto text-sm text-center ">
        {/* MENU */}
        {getNavigationForUser(currentUser).map((item, index) => {
          const active = location.pathname === item.link;
          return item.link ? (
            <Link
              key={index}
              to={item.link}
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${active ? "text-neutral-900 dark:text-neutral-100" : ""
                }`}
            >
              <item.icon
                className={`w-6 h-6 ${active ? "text-red-600" : ""}`}
              />
              <span className="text-[11px] leading-none mt-1">{t(item.localizationKey || item.name, item.name)}</span>
            </Link>
          ) : (
            <div
              key={index}
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${active ? "text-neutral-900 dark:text-neutral-100" : ""
                }`}
            >
              <item.icon iconClassName="w-6 h-6" className={``} />
              <span className="text-[11px] leading-none mt-1">{t(item.localizationKey || item.name, item.name)}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FooterNav;

function getNavigationForUser(currentUser: import("../models/users").User | null | undefined): NavItem[] {
  if (!currentUser) {
    return getNavForGuest();
  }
  switch (currentUser.role) {
    case UserRole.STUDENT:
      return getNavForStudent();
    case UserRole.ADMIN:
      return getNavForAdmin();
    case UserRole.LANDLORD:
      return getNavForLandlord();
    default:
      return getNavForGuest();
  }

  function getNavForStudent(): NavItem[] {
    return [{
      name: "Explore",
      link: "/",
      icon: MagnifyingGlassIcon,
      localizationKey: "nav.explore",
    },
    {
      name: "My Applications",
      link: "/my-applications",
      icon: HeartIcon,
      localizationKey: "nav.myApplications",
    },
    {
      name: "Profile",
      link: "/account",
      icon: UserCircleIcon,
      localizationKey: "nav.profile",
    },
    {
      name: "Menu",
      icon: MenuBar,
      localizationKey: "nav.menu",
    }
    ];
  }

  function getNavForAdmin(): NavItem[] {
    return [{
      name: "Explore",
      link: "/",
      icon: MagnifyingGlassIcon,
      localizationKey: "nav.explore",
    },
    {
      name: "Registrations",
      link: "/registration-requests",
      icon: HeartIcon,
      localizationKey: "nav.registrationRequests",
    },
    {
      name: "Profile",
      link: "/account",
      icon: UserCircleIcon,
      localizationKey: "nav.profile",
    },
    {
      name: "Menu",
      icon: MenuBar,
      localizationKey: "nav.menu",
    }
    ];
  }

  function getNavForLandlord(): NavItem[] {
    return [{
      name: "Explore",
      link: "/",
      icon: MagnifyingGlassIcon,
      localizationKey: "nav.explore",

    },
    {
      name: "My Listings",
      link: "/my-listings",
      icon: HeartIcon,
      localizationKey: "nav.myListings",
    },
    {
      name: "Profile",
      link: "/account",
      icon: UserCircleIcon,
      localizationKey: "nav.profile",
    },
    {
      name: "Menu",
      icon: MenuBar,
      localizationKey: "nav.menu",
    }
    ];
  }

  function getNavForGuest(): NavItem[] {
    return [
      {
        name: "Explore",
        link: "/",
        icon: MagnifyingGlassIcon,
        localizationKey: "nav.explore",
      },
      {
        name: "Log in",
        link: "/login",
        icon: UserCircleIcon,
        localizationKey: "common.button.login",
      },
      {
        name: "Menu",
        icon: MenuBar,
        localizationKey: "nav.menu",
      }];
  }
}
