import { AxiosResponse } from "axios";
import { AdditonalCost, Address, Image, Listing, UnitType } from "models/listings";
import { ResponseObject } from "models/responses";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createListing, deleteImage, getById, updateListing, updateStatus, uploadImages } from "services/listing-service";
import ListingOverview from "./ListingOverview";
import ManageListingAmenities from "./ManageListingAmenities";
import ManageListingDescription from "./ManageListingDescription";
import ManageListingDetails from "./ManageListingDetails";
import ManageListingImages from "./ManageListingImages";
import ManageListingLocation from "./ManageListingLocation";
import ManageListingPrice from "./ManageListingPrice";
import ManageListingSize from "./ManageListingSize";

export interface PageAddListingProps { }

export interface PropertyPrice {
    baseRent: number;
    ancillaryCost: number;
    securityDeposit: number;
    additionalCosts: AdditonalCost[];
}

export interface PropertyPriceValidationError {
    baseRent: string;
    ancillaryCost: string;
    securityDeposit: string;
}

export interface PropertyDetails {
    title: string;
    propertyType: UnitType;
}

export interface PropertyDetailsValidationError {
    title: string;
    propertyType: string;
}

export interface PropertyLocation {
    address: Address;
}

export interface PropertyLocationValidationError {
    street: string;
    city: string;
    region: string;
    postalCode: string;
    district?: string;
    houseNumber: string;
}

export interface PropertyImages {
    images?: File[];
}

export interface PropertyImagesValidationError {
    images: string;
}

export interface PropertySize {
    areaSqm: number;
    totalAreaSqm: number;
    bedrooms: number;
    bathrooms: number;
    kitchens: number;
}

export interface PropertySizeValidationError {
    areaSqm: string;
    totalAreaSqm: string;
    bedrooms: string;
    bathrooms: string;
    kitchens: string;
}


export interface PropertyAmenities {
    amenities: string[];
}

export interface PropertyDescription {
    description: string;
    locationDescription: string;
    tenantDescription: string;
}

export interface PropertyDescriptionValidationError {
    description: string;
    locationDescription: string;
    tenantDescription: string;
}

const DEFAULT_TENANT_DESCRIPTION =
    `Wir sind *ANZAHL*  Frauen und *ANZAHL* Männer im Alter von *ALTER* bis *ALTER*
Gesprochene Sprachen sind *SPRACHE*
Wir suchen *GESCHLECHT* im Alter von *ALTER* bis *ALTER*`;


const PageManageListing: FC<PageAddListingProps> = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams()
    const [editId, setEditId] = useState<string | undefined>();
    const [currentPage, setCurrentPage] = useState(1);
    // add tite and property type
    const [listing, setListing] = useState<Listing>({} as Listing);
    const [propertyDetails, setPropertyDetails] = useState<PropertyDetails>({ propertyType: "APARTMENT" } as PropertyDetails);
    const [propertySize, setPropertySize] = useState<PropertySize>({ bathrooms: 1, bedrooms: 1, kitchens: 1 } as PropertySize);
    const [propertyPrice, setPropertyPrice] = useState<PropertyPrice>({ baseRent: 0, ancillaryCost: 0, securityDeposit: 0, additionalCosts: [] } as PropertyPrice);
    const [propertyLocation, setPropertyLocation] = useState<Address>({ region: "Bayern" } as Address);
    const [propertyAmenities, setPropertyAmenities] = useState<PropertyAmenities>({ amenities: ["ENERGY_CERTIFICATE"] } as PropertyAmenities);
    const [propertyDescription, setPropertyDescription] = useState<PropertyDescription>({ tenantDescription: DEFAULT_TENANT_DESCRIPTION } as PropertyDescription);
    const [propertyImages, setPropertyImages] = useState<PropertyImages>({} as PropertyImages);
    const [detailsValidationError, setDetailsValidationError] = useState<PropertyDetailsValidationError>({} as PropertyDetailsValidationError);
    const [sizeValidationError, setSizeValidationError] = useState<PropertySizeValidationError>({} as PropertySizeValidationError);
    const [priceValidationError, setPriceValidationError] = useState<PropertyPriceValidationError>({} as PropertyPriceValidationError);
    const [locationValidationError, setLocationValidationError] = useState<PropertyLocationValidationError>({} as PropertyLocationValidationError);
    const [descriptionValidationError, setDescriptionValidationError] = useState<PropertyDescriptionValidationError>({} as PropertyDescriptionValidationError);
    const [imagesValidationError, setImagesValidationError] = useState<PropertyImagesValidationError>({} as PropertyImagesValidationError);

    const [edit, setEdit] = useState(false);
    const [saving, setSaving] = useState(false);
    // if location === manage-listing/:id

    useEffect(() => {
        if (location.pathname.includes("manage-listing") && id) {
            setEdit(true);
            getById(id).then((response) => {
                populateFromListing(response.data.payload);
            }).catch((error) => {
                navigate("/page-404");
            });
        }
    }, [id, editId]);

    const handleDetailsChange = (nextPage: number, details: PropertyDetails) => {
        setPropertyDetails(details);
        setListing({ ...listing, title: details.title, unitType: details.propertyType });
        // validate 
        if (validateDetails(details)) {
            setCurrentPage(nextPage);
        }
    }

    const handleLocationChange = (nextPage: number, data: Address) => {
        setPropertyLocation(data);
        setListing({ ...listing, address: data });
        // validate 
        if (validateLocation(data)) {
            setCurrentPage(nextPage);
        }
    }

    const handleSizeChange = (nextPage: number, data: PropertySize) => {
        setPropertySize(data);
        setListing({
            ...listing, areaSqm:
                data.areaSqm,
            totalAreaSqm: data.totalAreaSqm,
            bedrooms: data.bedrooms,
            bathrooms: data.bathrooms,
            kitchens: data.kitchens
        });
        // validate 
        if (validateSize(data)) {
            setCurrentPage(nextPage);
        }
    }

    const handleAmnetiesChange = (nextPage: number, data: PropertyAmenities) => {
        setCurrentPage(nextPage);
        setPropertyAmenities(data);
        setListing({ ...listing, amenities: data.amenities });
    }

    const handleDescriptionChange = (nextPage: number, data: PropertyDescription) => {
        setPropertyDescription(data);
        setListing({
            ...listing,
            description: data.description,
            locationDescription: data.locationDescription,
            tenantDescription: data.tenantDescription
        });
        // validate 
        if (validateDescription(data)) {
            setCurrentPage(nextPage);
        }
    }

    const handlePriceChange = (nextPage: number, data: PropertyPrice) => {
        setPropertyPrice(data);
        setListing({ ...listing, basicRent: data.baseRent, ancillaryCost: data.ancillaryCost, securityDeposit: data.securityDeposit, additionalCosts: data.additionalCosts });
        // validate 
        if (validatePrice(data)) {
            setCurrentPage(nextPage);
        }
    }

    const handleImageChange = (nextPage: number, data: PropertyImages) => {
        setPropertyImages(data);
        setCurrentPage(nextPage);
    }

    const handleImageChangeAndSave = (nextPage: number, data: PropertyImages) => {
        setPropertyImages(data);
        // validate 
        if (validateImages(listing, data)) {
            saveAndFinish(nextPage, data);
        }
    }


    const validateDetails = (details: PropertyDetails) => {
        let errors = {} as PropertyDetailsValidationError;
        let isValid = true;
        if (!details.title || !details.propertyType) {
            if (!details.title) {
                errors.title = t("common.validation.required", "This field is required");
            }
            if (!details.propertyType) {
                errors.propertyType = t("common.validation.required", "This field is required");
            }
            isValid = false;
        }

        setDetailsValidationError(errors);
        return isValid;
    }

    const validateLocation = (address: Address) => {
        let errors = {} as PropertyLocationValidationError;
        let isValid = true;
        if (!address.street || !address.city || !address.region || !address.postalCode || !address.houseNumber) {
            if (!address.street) {
                errors.street = t("common.validation.required", "This field is required");
            }
            if (!address.city) {
                errors.city = t("common.validation.required", "This field is required");
            }
            if (!address.region) {
                errors.region = t("common.validation.required", "This field is required");
            }
            if (!address.postalCode) {
                errors.postalCode = t("common.validation.required", "This field is required");
            }
            if (!address.houseNumber) {
                errors.houseNumber = t("common.validation.required", "This field is required");
            }
            isValid = false;
        }

        setLocationValidationError(errors);
        return isValid;
    }

    const validateSize = (size: PropertySize) => {
        let errors = {} as PropertySizeValidationError;
        let isValid = true;
        if (!size.areaSqm || isNullOrUndefined(size.bedrooms) || isNullOrUndefined(size.bathrooms) || isNullOrUndefined(size.kitchens) || !size.totalAreaSqm) {
            if (!size.areaSqm || size.areaSqm <= 1) {
                errors.areaSqm = t("common.validation.required", "This field is required");
            }
            if (!size.totalAreaSqm || size.totalAreaSqm <= 1) {
                errors.totalAreaSqm = t("common.validation.required", "This field is required");
            }
            if (isNullOrUndefined(size.bedrooms)) {
                errors.bedrooms = t("common.validation.required", "This field is required");
            }
            if (isNullOrUndefined(size.bathrooms)) {
                errors.bathrooms = t("common.validation.required", "This field is required");
            }
            if (isNullOrUndefined(size.kitchens)) {
                errors.kitchens = t("common.validation.required", "This field is required");
            }
            isValid = false;
        }

        setSizeValidationError(errors);
        return isValid;
    }

    const isNullOrUndefined = (value: any) => {
        return value === null || value === undefined;
    }

    const validatePrice = (price: PropertyPrice) => {
        let errors = {} as PropertyPriceValidationError;
        let isValid = true;
        if (!price.baseRent || price.baseRent <= 1 || price.ancillaryCost === undefined || price.ancillaryCost === null) {
            if (!price.baseRent || price.baseRent <= 1) {
                errors.baseRent = t("common.validation.required", "This field is required");
            }
            if (!price.ancillaryCost) {
                errors.ancillaryCost = t("common.validation.required", "This field is required");
            }
            if (!price.securityDeposit) {
                errors.securityDeposit = t("common.validation.required", "This field is required");
            }
            isValid = false;
        }

        setPriceValidationError(errors);
        return isValid;
    }

    const validateDescription = (description: PropertyDescription) => {
        let errors = {} as PropertyDescriptionValidationError;
        let isValid = true;
        if (!description.description) {
            errors.description = t("common.validation.required", "This field is required");
            isValid = false;
        }
        if (!description.locationDescription) {
            errors.locationDescription = t("common.validation.required", "This field is required");
            isValid = false;
        }
        if (!description.tenantDescription) {
            errors.tenantDescription = t("common.validation.required", "This field is required");
            isValid = false;
        }


        setDescriptionValidationError(errors);
        return isValid;
    }

    const validateImages = (listing: Listing, image: PropertyImages) => {
        let errors = {} as PropertyImagesValidationError;
        let isValid = true;
        if (listing.images && listing.images.length > 0) {
            setImagesValidationError(errors);
            return isValid;
        }
        if (!image.images || (image.images && image.images.length === 0)) {
            errors.images = t("listing.validation.images.required", "At least one image is required");
            isValid = false;
        }

        setImagesValidationError(errors);
        return isValid;
    }



    const saveAndFinish = (nextPage: number, data: PropertyImages) => {
        let promise = {} as Promise<AxiosResponse<ResponseObject<Listing>>>;
        setSaving(true);
        if (edit) {
            promise = updateListing(listing.externalId, listing);
        } else {
            promise = createListing(listing);
        }

        promise.then((response) => {
            console.log(response);
            setListing(response.data.payload)
            setEdit(true);
            const id = response.data.payload.externalId;
            setEditId(id);
            if (data.images && data.images.length > 0) {
                uploadImages(id, data.images).then((response) => {
                    goToLastPage(id, nextPage)
                }).catch((error) => {
                    console.error(error);
                    setSaving(false);
                });
            } else {
                setSaving(false);
                goToLastPage(id, nextPage)
            }
        }).catch((error) => {
            console.error(error);
            setSaving(false);
        });
    }

    const goToLastPage = (rentalId: string, nextPage: number) => {
        getById(rentalId).then((response) => {
            setListing(response.data.payload);
            setCurrentPage(nextPage);
        }).catch((error) => {
            console.error(error);
        });
    }

    const handlePublish = () => {
        if (listing?.externalId) {
            updateStatus(listing.externalId, "AVAILABLE").then((response) => {
                navigate("/listing/" + listing.externalId);
            }).catch((error) => {
                console.error(error);
            });
        }
    }

    const handleEdit = () => {
        if (listing?.externalId) {
            setCurrentPage(1);
            navigate("/manage-listing/" + listing.externalId);
        }
    }

    const populateFromListing = (listing: Listing) => {
        setListing(listing);
        setPropertyLocation(listing.address);
        setPropertyDetails({ title: listing.title, propertyType: listing.unitType });
        setPropertySize({ areaSqm: listing.areaSqm, totalAreaSqm: listing.totalAreaSqm, bedrooms: listing.bedrooms, bathrooms: listing.bathrooms, kitchens: listing.kitchens });
        setPropertyPrice({ baseRent: listing.basicRent, ancillaryCost: listing.ancillaryCost, securityDeposit: listing.securityDeposit, additionalCosts: listing.additionalCosts });
        setPropertyAmenities({ amenities: listing.amenities });
        setPropertyDescription({ description: listing.description, locationDescription: listing.locationDescription, tenantDescription: listing.tenantDescription });
        setPropertyImages({} as PropertyImages);
    }

    const handleOnImageDelete = (image: Image) => {
        deleteImage(listing.externalId, image.externalId).then((response) => {
            const images = listing.images.filter((img) => img.externalId !== image.externalId);
            setListing({ ...listing, images: images });
        }).catch((error) => {
            console.error(error);
        });
    }


    function renderForm() {

        switch (currentPage) {
            case 1:
                return <ManageListingDetails validationErrors={detailsValidationError} maxIndex="08" index="01" formData={propertyDetails} onNextPage={(details) => handleDetailsChange(2, details)} />;
            case 2:
                return <ManageListingLocation validationErrors={locationValidationError} maxIndex="08" index="02" formData={propertyLocation} onNextPage={(data) => handleLocationChange(3, data)} onBackPage={(data) => handleLocationChange(1, data)} />;
            case 3:
                return <ManageListingSize propertyType={propertyDetails.propertyType} validationErrors={sizeValidationError} maxIndex="08" index="03" formData={propertySize} onNextPage={(data) => handleSizeChange(4, data)} onBackPage={(data) => handleSizeChange(2, data)} />;
            case 4:
                return <ManageListingAmenities maxIndex="08" index="04" formData={propertyAmenities} onNextPage={(data) => handleAmnetiesChange(5, data)} onBackPage={(data) => handleAmnetiesChange(3, data)} />;
            case 5:
                return <ManageListingDescription validationErrors={descriptionValidationError} maxIndex="08" index="05" formData={propertyDescription} onNextPage={(data) => handleDescriptionChange(6, data)} onBackPage={(data) => handleDescriptionChange(4, data)} />;
            case 6:
                return <ManageListingPrice validationErrors={priceValidationError} maxIndex="08" index="06" formData={propertyPrice} onNextPage={(data) => handlePriceChange(7, data)} onBackPage={(data) => handlePriceChange(5, data)} />;
            case 7:
                return <ManageListingImages validationErrors={imagesValidationError} disableNavigation={saving} nextBtnText={t("common.button.save", "Save")} onExistingImageDelete={edit ? handleOnImageDelete : undefined} existingImages={listing.images} formData={propertyImages} maxIndex="08" index="07" onNextPage={(data) => handleImageChangeAndSave(8, data)} onBackPage={(data) => handleImageChange(6, data)} />;
            case 8:
                return <ListingOverview maxIndex="08" index="08" onPublish={handlePublish} onEdit={handleEdit} listing={listing} />;
        }
    }

    return (
        <>
            <Helmet>
                <title>{t(`page.manageListings.helmet.${edit ? 'edit' : 'new'}`, "Manage Listing")}</title>
            </Helmet>
            {renderForm()}
        </>
    );


};



export default PageManageListing;