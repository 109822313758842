import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { PropertyPrice, PropertyPriceValidationError } from "./PageManageListing";

export interface ManageListingPriceProps {
  formData: PropertyPrice;
  onNextPage?: (data: PropertyPrice) => void;
  onBackPage?: (data: PropertyPrice) => void;
  index: string;
  maxIndex: string;
  validationErrors: PropertyPriceValidationError;
}

const ManageListingPrice: FC<ManageListingPriceProps> = ({ onBackPage, onNextPage, formData, index, maxIndex, validationErrors }) => {
  const { t } = useTranslation();
  const [internalFormData, setInternalFormData] = useState<PropertyPrice>(formData);

  const handleOnNextPage = () => {
    if (onNextPage)
      onNextPage(internalFormData);
  }

  const handleOnBackPage = () => {
    if (onBackPage)
      onBackPage(internalFormData);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInternalFormData({
      ...internalFormData,
      [e.target.name]: e.target.value
    });
  }


  const handleAddCost = () => {
    const costs = internalFormData.additionalCosts || [];
    costs.push({ description: "", cost: 0 });
    setInternalFormData({
      ...internalFormData,
      additionalCosts: costs
    });
  };

  const handleRemoveCost = (index: number) => {
    const newCosts = internalFormData.additionalCosts.filter((_, i) => i !== index);
    setInternalFormData({
      ...internalFormData,
      additionalCosts: newCosts
    });
  };

  const handleAdditionalCostChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newCosts = internalFormData.additionalCosts.map((cost, i) => (i === index ? { ...cost, [name]: value } : cost));
    setInternalFormData({
      ...internalFormData,
      additionalCosts: newCosts
    });
  };



  return (
    <CommonLayout
      index={index}
      maxIndex={maxIndex}
      onBackPage={handleOnBackPage}
      onNextPage={handleOnNextPage}
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">{t("page.manageListings.price.title", "Pricing")}</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {t("page.manageListings.price.description", "Set the price for your listing")}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem label={t("page.manageListings.price.field.baseRent.label", "Base Rent")}>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500">€</span>
              </div>
              <Input name="baseRent" value={internalFormData.baseRent} onChange={handleChange} className="!pl-8 !pr-10" placeholder="0.00" />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500">EUR</span>
              </div>
            </div>
            {validationErrors.baseRent && <span className="text-red-500 dark:text-red-400 text-sm">{validationErrors.baseRent}</span>}
          </FormItem>
          {/* ----- */}
          <FormItem label={t("page.manageListings.price.field.ancillaryCost.label", "Ancilary Costs")}>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500">€</span>
              </div>
              <Input name="ancillaryCost" value={internalFormData.ancillaryCost} onChange={handleChange} className="!pl-8 !pr-10" placeholder="0.00" />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500">EUR</span>
              </div>
            </div>
            {validationErrors.ancillaryCost && <span className="text-red-500 dark:text-red-400 text-sm">{validationErrors.ancillaryCost}<br/></span>}
            <span className="text-neutral-500 dark:text-neutral-400 text-sm">
              {t("page.manageListings.price.field.ancillaryCost.note", "Note: Ancillary costs are additional costs that are not included in the base rent")}
            </span>
            
          </FormItem>
          {/* ----- */}
          <FormItem label={t("page.manageListings.price.field.securityDeposit.label", "Security Deposit")}>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500">€</span>
              </div>
              <Input name="securityDeposit" value={internalFormData.securityDeposit} onChange={handleChange} className="!pl-8 !pr-10" placeholder="0.00" />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500">EUR</span>
              </div>
            </div>
            {validationErrors.securityDeposit && <span className="text-red-500 dark:text-red-400 text-sm">{validationErrors.securityDeposit}</span>}
          </FormItem>
          {/* Additional Costs */}
          <h3 className="text-lg font-semibold">{t("page.manageListings.price.field.additionalCosts.title", "Additional Costs")}</h3>
          {internalFormData.additionalCosts.map((cost, index) => (
            <div key={index} className="flex space-x-4 items-end">
              <FormItem label={t("page.manageListings.price.field.additionalCosts.description.label", "Description")} className="flex-1">
                <Input
                  name="description"
                  value={cost.description}
                  onChange={(e) => handleAdditionalCostChange(index, e)}
                  placeholder={t("page.manageListings.price.field.additionalCosts.description.placeholder", "Enter description")}
                />
              </FormItem>
              <FormItem label={t("page.manageListings.price.field.additionalCosts.cost.label", "Cost")} className="flex-1">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500">€</span>
                  </div>
                  <Input
                    name="cost"
                    type="number"
                    value={cost.cost}
                    onChange={(e) => handleAdditionalCostChange(index, e)}
                    className="!pl-8 !pr-10"
                    placeholder="0.00"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500">EUR</span>
                  </div>
                </div>
              </FormItem>
              <ButtonSecondary className="hover:bg-red-300 hover:dark:bg-red-800" onClick={() => handleRemoveCost(index)}>
                {t("common.button.delete", "Remove")}
              </ButtonSecondary>
            </div>
          ))}
          <ButtonSecondary className="hover:bg-green-200 hover:dark:bg-green-800" onClick={handleAddCost} >
            {t("common.button.add", "Add Additional Cost")}
          </ButtonSecondary>
        </div>
      </>
    </CommonLayout>
  );
};

export default ManageListingPrice;
