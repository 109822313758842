import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import UserInformationPopup from "containers/User/UserInformationPopup";
import { approvalStatuses } from "data/constants";
import { useApplications } from "hooks/applications/useApplication";
import { Application, ApplicationFilter } from "models/applications";
import { ApprovalStatus } from "models/commons";
import { SortDirection } from "models/filters";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { reviewApplicationByAdmin } from "services/application-service";
import { requestDonationFromUser } from "services/user-service";
import { Alert } from "shared/Alert/Alert";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import FilterBar, { Filter } from "shared/Filter/FilterBar";
import PillMultiSelectFilter, { FilterOption } from "shared/Filter/PillMultiSelectFilter";
import { ConfirmationModal } from "shared/Modal/ConfirmationModal";
import Pagination from "shared/Pagination/Pagination";
import { formatDateTime } from "utils/formatDate";
import { getApprovalStatus } from "utils/getApprovalStatus";
import { getColorForStatus } from "utils/getColorForApprovalStatus";
import { getException } from "utils/getException";
import { getBasicFilterFromSearchParams } from "utils/queryParamFilterUtils";
import ApplicationLetterPopup from "../ApplicationLetter/ApplicationLetterPopup";

interface ApplicationSelectedFilterOptions {
    adminStatus: FilterOption<ApprovalStatus>[];
    landlordStatus: FilterOption<ApprovalStatus>[];
}


const AdminApplicationReviewPage = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [filter, setFilter] = useState<ApplicationFilter>({} as ApplicationFilter);
    const { data, error, isLoading } = useApplications(filter, [filter]);
    const [currentPage, setCurrentPage] = useState(0);
    const [apiError, setApiError] = useState<string>();
    const [keywordAndSortingFilter, setKeywordAndSortingFilter] = useState<Filter>({} as Filter);
    const [selectedOptions, setSelectedOptions] = useState<ApplicationSelectedFilterOptions>({ adminStatus: [], landlordStatus: [] });
    

    const statusOptions = approvalStatuses.map((status) => {
        return { label: getApprovalStatus(t, status), value: status } as FilterOption<ApprovalStatus>;
    });

    const sortOptions = [
        { label: t("user.firstName", "First Name"), value: "student.firstName" },
        { label: t("user.lastName", "Last Name"), value: "student.lastName" },
        { label: t("user.email", "Email"), value: "student.email" },
        { label: t("listing.title", "Title"), value: "listing.title" },
        { label: t("common.dateCreated", "Date Created"), value: "dateCreated" },
        { label: t("common.lastModified", "Last Modified"), value: "lastModified" }
    ];

    useEffect(() => {
        if (data) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [data]);

    useEffect(() => {
        const newFilter = getBasicFilterFromSearchParams(searchParams, "lastModified") as ApplicationFilter;
        newFilter.landlordStatus = searchParams.getAll("landlordStatus") as ApprovalStatus[];
        newFilter.adminStatus = searchParams.has("adminStatus") ? searchParams.getAll("adminStatus") as ApprovalStatus[] : [ApprovalStatus.PENDING];
        setFilter(newFilter);
        if (newFilter.page) {
            setCurrentPage(newFilter.page);
        }
        setKeywordAndSortingFilter({
            keyword: newFilter.keyword || "",
            sortField: newFilter.sortField,
            direction: newFilter.direction
        });
        setSelectedOptions({
            adminStatus: newFilter.adminStatus ? statusOptions.filter((option) => newFilter.adminStatus?.includes(option.value)) : [],
            landlordStatus: newFilter.landlordStatus ? statusOptions.filter((option) => newFilter.landlordStatus?.includes(option.value)) : []
        });
    }, [searchParams]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        setFilter({ ...filter, page });
    };

    const handleApplicationReview = (item: Application, approved: boolean) => {
        setApiError(undefined)
        reviewApplicationByAdmin(item.externalId, approved ? ApprovalStatus.APPROVED : ApprovalStatus.REJECTED).then(() => {
            setFilter({ ...filter, page: currentPage });
        }).catch((error) => {
            setApiError(getException(t, error.response.data.error.message));
        });
    }

    const handleAdminStatusChanged = (selectedOptions: FilterOption<ApprovalStatus>[]) => {
        const newFilter = { ...filter, adminStatus: selectedOptions.map((option) => option.value) };
        setFilter(newFilter);
        searchParams.delete("adminStatus");
        if (selectedOptions.length > 0) {
            selectedOptions.forEach(option => searchParams.append("adminStatus", option.value));
        }
        else {
            searchParams.set("adminStatus", "");
        }
        setSearchParams(searchParams);
    }

    const handleLandlordStatusChanged = (selectedOptions: FilterOption<ApprovalStatus>[]) => {
        const newFilter = { ...filter, landlordStatus: selectedOptions.map((option) => option.value) };
        setFilter(newFilter);
        searchParams.delete("landlordStatus");
        selectedOptions.forEach(option => searchParams.append("landlordStatus", option.value));
        setSearchParams(searchParams);
    }

    const handleKeywordChange = (keyword: string) => {
        const newFilter = { ...filter, keyword: keyword };
        setFilter(newFilter);
        searchParams.set("keyword", keyword);
        setSearchParams(searchParams);
    }

    const handleSortChange = (sortField: string) => {
        const newFilter = { ...filter, sort: sortField };
        setFilter(newFilter);
        searchParams.set("sortField", sortField);
        setSearchParams(searchParams);
    }

    const handleDirectionChange = (direction: SortDirection) => {
        const newFilter = { ...filter, direction: direction };
        setFilter(newFilter);
        searchParams.set("sortDirection", direction);
        setSearchParams(searchParams);
    }

    const handleSendDonationEmail = (item: Application) => {
        setApiError(undefined)
        requestDonationFromUser(item.landlord.externalId).then(() => {
        }).catch((error) => {
            setApiError(getException(t, error.response.data.error.message));
        });
    }

    const renderSection1 = () => {
        return (

            <div className="space-y-6 sm:space-y-8">
                <Helmet>
                    <title>{t("page.applicationReview.helmet", "Applications")}</title>
                </Helmet>
                <div>
                    <h2 className="text-3xl font-semibold">{t("page.applicationReview.title", "Applications")}</h2>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

                {apiError && (
                    <div className="w-full">
                        <Alert type="error" >
                            {apiError}
                        </Alert>
                    </div>
                )}

                <div className="group relative  bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl p-10">

                    <div className="space-y-8 pb-8">
                        <FilterBar
                            filter={keywordAndSortingFilter}
                            sortOptions={sortOptions}
                            onDirectionChange={handleDirectionChange}
                            onKeywordChange={handleKeywordChange}
                            onSortChange={handleSortChange}
                        />
                        <div className="flex space-x-4">
                            <PillMultiSelectFilter
                                label={t("page.applicationReview.adminStatus", "Admin Status")}
                                defaultSelection={selectedOptions.adminStatus}
                                options={statusOptions}
                                onSelectionChange={handleAdminStatusChanged} />
                            <PillMultiSelectFilter
                                label={t("page.applicationReview.landlordStatus", "Landlord Status")}
                                defaultSelection={selectedOptions.landlordStatus}
                                options={statusOptions}
                                onSelectionChange={handleLandlordStatusChanged} />
                        </div>
                    </div>


                    <div className="relative overflow-x-auto sm:rounded-lg">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 rounded-3xl">
                                <tr>
                                    <th scope="col" className="px-6 py-3" >{t("page.applicationReview.table.student", "Student")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("application.applicationLetter", "Application letter")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("page.applicationReview.table.landlord", "Landlord")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("page.applicationReview.table.listing", "Listing")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("page.applicationReview.table.status", "Status")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("common.lastModified", "Last Modified")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("page.applicationReview.table.review", "Review")}</th>
                                </tr>
                            </thead>

                            <tbody>
                                {data?.payload.map((item) => (
                                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                        <td className="px-6 py-4"><UserInformationPopup user={item.student} /></td>
                                        <td className="px-6 py-4"><ApplicationLetterPopup applicationLetter={item.applicationLetter} /></td>
                                        <td className="px-6 py-4"><UserInformationPopup user={item.landlord} /></td>
                                        <td className="px-6 py-4">
                                            <div className="flex flex-row space-x-2">
                                                {/* <span >
                                                    {item.listing.title}
                                                </span> */}
                                                <Link target="_blank" rel="noopener noreferrer" to={`/listing/${item.listing.externalId}`} >
                                                    <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                                                </Link>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="flex flex-col space-y-2">
                                                <span>
                                                    {t("page.applicationReview.table.adminReview", "Admin Review:")} <Badge name={getApprovalStatus(t, item.administratorApprovalStatus)} color={getColorForStatus(item.administratorApprovalStatus)} />
                                                </span>
                                                {item.administratorApprovalStatus === ApprovalStatus.APPROVED && (
                                                    <span>
                                                        {t("page.applicationReview.table.landlordReview", "Landlord Review:")} <Badge name={getApprovalStatus(t, item.status)} color={getColorForStatus(item.status)} />
                                                    </span>
                                                )}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">{formatDateTime(item.lastModified)}</td>
                                        <td className="px-6 py-4">
                                            <div>
                                                {item.administratorApprovalStatus === "PENDING" && (
                                                    <ConfirmationModal confirmText={t("application.actions.review.approve", "Accept")} cancelText={t("application.actions.review.reject", "Reject")} title={t("application.actions.review.title", "Review Application")}
                                                        message={t("application.actions.review.description", "Please confirm your decision on this application")}
                                                        onConfirm={() => handleApplicationReview(item, true)} onCancel={() => handleApplicationReview(item, false)}
                                                        renderTrigger={(openModal: Function) => (
                                                            <ButtonPrimary onClick={() => openModal()}>{t("application.actions.review.review", "Review")}</ButtonPrimary>
                                                        )} />
                                                )}
                                                {item.administratorApprovalStatus === ApprovalStatus.APPROVED && (
                                                    <ConfirmationModal confirmText={t("application.actions.donation.send", "Send")} cancelText={t("common.button.cancel", "Cancel")} title={t("application.actions.donation.title", "Donation Request")}
                                                        message={t("application.actions.donation.description", "Do you want to send the donation request to the landlord?")}
                                                        onConfirm={() => handleSendDonationEmail(item)} onCancel={() => { }}
                                                        renderTrigger={(openModal: Function) => (
                                                            <ButtonPrimary onClick={() => openModal()}>
                                                                <i className="las la-paper-plane"></i>
                                                                <span className="pl-2">
                                                                    {t("application.actions.donation.button", "Donation Request")}
                                                                </span></ButtonPrimary>
                                                        )} />
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {data && data.totalPages > 1 && (
                        <div className="flex mt-16 justify-center items-center">
                            <Pagination
                                totalPages={data.totalPages}
                                currentPage={currentPage}
                                setCurrentPage={handlePageChange}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900 ">

            <div className="container pt-6 pb-24 lg:pb-32">
                {renderSection1()}
            </div>
        </div>
    );
};



export default AdminApplicationReviewPage;
