import Label from "components/Label/Label";
import { isEmpty } from "lodash";
import { Gender, User, UserRole } from "models/users";
import { useAuth } from "providers/AuthProvider";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Alert } from "shared/Alert/Alert";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import { formatDate } from "utils/formatDate";
import { getGender } from "utils/getGender";
import CommonLayout from "./CommonLayout";

export interface AccountPageProps {
  className?: string;
}

interface ValidationErrors {
  firstName: string;
  lastName: string
  gender: string;
  phoneNumber: string;
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  aboutMe: string;

}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const { currentUser, handleUpdateProfile } = useAuth();
  const [user, setUser] = useState<User>({} as User);
  const [errors, setErrors] = useState<ValidationErrors>({} as ValidationErrors);
  const [updateSuccess, setUpdateSuccess] = useState<boolean | null>(null);

  useEffect(() => {
    if (currentUser)
      setUser(currentUser);
  }, [currentUser]);

  const handleGenderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setUser({
      ...user, gender: e.target.value as Gender
    });
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    });
  }

  const handleAboutMeChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    });
  }



  const validate = (): boolean => {
    const hasErrors = false;
    if (isEmpty(user.firstName)) {
      setErrors({ ...errors, firstName: t("common.validation.required", "Required") });
      return true;
    }
    if (isEmpty(user.lastName)) {
      setErrors({ ...errors, lastName: t("common.validation.required", "Required") });
      return true;
    }
    if (isEmpty(user.phoneNumber)) {
      setErrors({ ...errors, phoneNumber: t("common.validation.required", "Required") });
      return true;
    }
    if (!user.gender) {
      setErrors({ ...errors, gender: t("common.validation.required", "Required") });
      return true;
    }

    if (user.role === UserRole.LANDLORD) {
      if (isEmpty(user.street)) {
        setErrors({ ...errors, street: t("common.validation.required", "Required") });
        return true;
      }
      if (isEmpty(user.houseNumber)) {
        setErrors({ ...errors, houseNumber: t("common.validation.required", "Required") });
        return true;
      }
      if (isEmpty(user.postalCode)) {
        setErrors({ ...errors, postalCode: t("common.validation.required", "Required") });
        return true;
      }
      if (isEmpty(user.city)) {
        setErrors({ ...errors, city: t("common.validation.required", "Required") });
        return true;
      }
    }

    if (user.role === UserRole.STUDENT) {
      if (isEmpty(user.aboutMe)) {
        setErrors({ ...errors, aboutMe: t("common.validation.required", "Required") });
        return true;
      }
    }

    return !hasErrors;
  }

  const updateInfo = () => {
    // validate the user object
    setErrors({} as ValidationErrors);
    if (validate()) {
      handleUpdateProfile(user).then(() => {
        setUpdateSuccess(true);
      }).catch(() => {
        setUpdateSuccess(false);
      });
    }
  }

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>{t("page.account.helmet", "Account")}</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">{t("page.account.title", "Account Information")}</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              <div>
                <Label>{t("user.gender", "Gender")}</Label>
                <Select
                  name="gender"
                  onChange={handleGenderChange}
                  value={user.gender}
                >
                  <option value={Gender.MALE}>{getGender(t, Gender.MALE)}</option>
                  <option value={Gender.FEMALE}>{getGender(t, Gender.FEMALE)}</option>
                  <option value={Gender.OTHER}>{getGender(t, Gender.OTHER)}</option>
                </Select>
                {errors.gender && <p className="text-red-500">{errors.gender}</p>}
              </div>
              <div>
                <Label>{t("user.firstName", "First Name")}</Label>
                <Input name="firstName" onChange={handleChange} disabled={false} className="mt-1.5" value={user?.firstName} />
                {errors.firstName && <p className="text-red-500">{errors.firstName}</p>}
              </div>
              <div>
                <Label>{t("user.lastName", "Last Name")}</Label>
                <Input name="lastName" onChange={handleChange} disabled={false} className="mt-1.5" value={user?.lastName} />
                {errors.lastName && <p className="text-red-500">{errors.lastName}</p>}
              </div>
              <div>
                <Label>{t("user.email", "Email Address")}</Label>
                <Input name="email" disabled={true} className="mt-1.5" value={user?.email} />
              </div>
              <div>
                <Label>{t("user.dateOfBirth", "Date of Birth")}</Label>
                <Input name="dateOfBirth" disabled={true} className="mt-1.5" value={formatDate(user?.dateOfBirth)} />
              </div>
              <div>
                <Label>{t("user.phoneNumber", "Phone Number")}</Label>
                <Input name="phoneNumber" onChange={handleChange} disabled={false} className="mt-1.5" value={user?.phoneNumber} />
                {errors.phoneNumber && <p className="text-red-500">{errors.phoneNumber}</p>}
              </div>
              {user?.role === UserRole.STUDENT && (
                <div>
                  <Label>{t("user.aboutMe", "About me")}</Label>
                  <Textarea name="aboutMe" onChange={handleAboutMeChange} disabled={false} className="mt-1.5" rows={10} value={user?.aboutMe || ""} />
                  {errors.aboutMe && <p className="text-red-500">{errors.aboutMe}</p>}
                </div>
              )}
              {user?.role === UserRole.LANDLORD && (
                <>
                  <div className="grid grid-cols-3 gap-4">
                    <div className="block col-span-2">
                      <Label>{t("user.address.street", "Street")}</Label>
                      <Input name="street" onChange={handleChange} disabled={false} className="mt-1.5" value={user?.street || ""} />
                      {errors.street && <p className="text-red-500">{errors.street}</p>}
                    </div>
                    <div className="block col-span-1">
                      <Label>{t("user.address.houseNumber", "House Number")}</Label>
                      <Input name="houseNumber" onChange={handleChange} disabled={false} className="mt-1.5" value={user?.houseNumber || ""} />
                      {errors.houseNumber && <p className="text-red-500">{errors.houseNumber}</p>}
                    </div>
                  </div>
                  <div className="grid grid-cols-3 gap-4">
                    <div className="block col-span-1">
                      <Label>{t("user.address.postalCode", "Postal Code")}</Label>
                      <Input name="postalCode" onChange={handleChange} disabled={false} className="mt-1.5" value={user?.postalCode || ""} />
                      {errors.postalCode && <p className="text-red-500">{errors.postalCode}</p>}
                    </div>
                    <div className="block col-span-2">
                      <Label>{t("user.address.city", "City")}</Label>
                      <Input name="city" onChange={handleChange} disabled={false} className="mt-1.5" value={user?.city || ""} />
                      {errors.city && <p className="text-red-500">{errors.city}</p>}
                    </div>
                  </div>
                </>
              )}

              <div>
                <ButtonPrimary onClick={updateInfo}>{t("common.button.update", "Update")}</ButtonPrimary>
                {updateSuccess === true && (
                  <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium py-8">
                    <Alert type="success">
                      {t("page.account.update.success", "Update successfully")}
                    </Alert>
                  </span>
                )}
                {updateSuccess === false && (
                  <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium py-8">
                    <Alert type="error">
                      {t("page.account.update.error", "Update Failed")}
                    </Alert>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
function validate() {
  throw new Error("Function not implemented.");
}

