import ListingCard from "components/ListingCard/ListingCard";
import { Listing } from "models/listings";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";

export interface ListingOverviewProps {
  onPublish: () => void;
  onEdit: () => void;
  listing: Listing;
  index: string;
  maxIndex: string;
}

const ListingOverview: FC<ListingOverviewProps> = ({ onPublish, onEdit, index, maxIndex, listing }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onBackPage = () => {
    navigate("/my-listings");
  };

  if (!listing) {
    return null;
  }

  const handlePreview = () => {
    navigate(`/listing/${listing.externalId}`);
  }

  return (
    <CommonLayout
      nextBtnText={t("page.manageListings.overview.button.publish", "Publish Listing")}
      backBtnText={t("page.manageListings.overview.button.keepDraft", "Keep as Draft")}
      index={index}
      maxIndex={maxIndex}
      onNextPage={onPublish}
      onBackPage={onBackPage}
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">{t("page.manageListings.overview.title", "Congratulations 🎉")}</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {t("page.manageListings.overview.description", "Excellent, congratulations on completing the listing.")}
          </span>
        </div>
        <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div>
          <h3 className="text-lg font-semibold">{t("page.manageListings.overview.subtitle", "This is your listing")}</h3>
          <div className="max-w">
            <ListingCard
              className="mt-8"
              listing={listing} href={""} />
          </div>
          <div className="flex items-center space-x-5 mt-8">
            <ButtonSecondary onClick={onEdit}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
              <span className="ml-3">{t("common.button.edit", "Edit")}</span>
            </ButtonSecondary>
            <Link to={`/listing/${listing.externalId}`} target="_blank">
              <ButtonPrimary>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>

                <span className="ml-3">{t("page.manageListings.overview.button.preview", "Preview")}</span>
              </ButtonPrimary>
            </Link>

          </div>
        </div>
        {/*  */}
      </>
    </CommonLayout >
  );
};

export default ListingOverview;
