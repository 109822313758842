import { Image } from "models/listings";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import convertImageLocationToUrl from "utils/convertImageLocationToUrl";
import CommonLayout from "./CommonLayout";
import { PropertyImages, PropertyImagesValidationError } from "./PageManageListing";

export interface ManageListingImagesProps {
  formData: PropertyImages;
  existingImages?: Image[];
  onExistingImageDelete?: (image: Image) => void;
  onNextPage: (data: PropertyImages) => void;
  onBackPage: (data: PropertyImages) => void;
  index: string;
  maxIndex: string;
  nextBtnText?: string;
  disableNavigation: boolean;
  validationErrors: PropertyImagesValidationError;
}

const ManageListingImages: FC<ManageListingImagesProps> = ({ onBackPage, onNextPage, index, formData, maxIndex, existingImages = [], onExistingImageDelete, nextBtnText, disableNavigation, validationErrors }) => {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState<PropertyImages>(formData);

  useEffect(() => {
    setSelectedFiles(formData);
  }, [formData]);

  const handleNewFiles = (fileList: FileList | null) => {
    if (!fileList) return;
    const files = Array.from(fileList);
    const imageFiles = files.filter(file => file.type.startsWith("image/"));
    if (imageFiles.length === 0) {
      return;
    }
    const existingFiles = selectedFiles.images || [];
    setSelectedFiles({ images: [...existingFiles, ...imageFiles] });
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    handleNewFiles(event.target.files);
  };

  const handleFileDropped = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    handleNewFiles(event.dataTransfer.files);
  };

  const handleOnBackPage = () => {
    if (onBackPage)
      onBackPage(selectedFiles);
  }

  const handleOnNextPage = () => {
    if (onNextPage)
      onNextPage(selectedFiles);
  }

  return (
    <CommonLayout
      index={index}
      maxIndex={maxIndex}
      onBackPage={handleOnBackPage}
      onNextPage={handleOnNextPage}
      nextBtnText={nextBtnText}
      disableNavigation={disableNavigation}
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">{t("page.manageListings.images.title", "Images")}</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {t("page.manageListings.images.description", "Upload images of your listing")}
          </span>
        </div>
        {existingImages && existingImages.length > 0 && (
          <div className="border-b border-neutral-200 dark:border-neutral-700 pb-8">

            <span className="text-lg font-semibold">{t("page.manageListings.images.existing", "Existing images")}</span>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-5">
              {existingImages.map((image, index) => (
                <div>
                  <div key={index} className="relative h-full w-full">
                    <img
                      className="object-cover rounded-md"
                      alt="{image.reference}"
                      src={convertImageLocationToUrl(image.location, 500, 500)}
                    />
                    {onExistingImageDelete && (
                      <button
                        className="absolute top-2 right-2 p-2 bg-neutral-100 dark:bg-neutral-800 rounded-full"
                        onClick={() => {
                          // remove image
                          onExistingImageDelete(image);
                        }}
                      >
                        <svg
                          className="w-4 h-4 text-neutral-500 dark:text-neutral-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div>
          <span className="text-lg font-semibold"> {t("page.manageListings.images.subtitle", "Pictures of the place")}</span>
          <div className="mt-5 ">
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md"
              onDrop={handleFileDropped}
              onDragOver={(e) => e.preventDefault()}
            >
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-neutral-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <div
                  className="flex text-sm text-neutral-6000 dark:text-neutral-300"
                >
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                  >
                    <span>{t("components.upload.multiple.button", "Upload files")}</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      accept="image/*"
                      multiple={true}
                      onChange={handleFileChange}
                    />
                  </label>
                  <p className="pl-1">{t("components.upload.drag", "or drag and drop")}</p>
                </div>
                <p className="text-xs text-neutral-500 dark:text-neutral-400">
                  {t("components.upload.descriptionImages", "PNG, JPG up to 10MB")}
                </p>
                {selectedFiles && (
                  <div>
                    <h4>{t("components.upload.multiple.selected", "Selected files:")}</h4>
                    <ul>
                      {selectedFiles.images?.map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {validationErrors.images && <span className="text-red-500 dark:text-red-400">{validationErrors.images}</span>}

              </div>
            </div>
          </div>
        </div>
      </>
    </CommonLayout >
  );
};

export default ManageListingImages;
