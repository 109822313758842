import { FC } from "react";

import { Squares2X2Icon } from "@heroicons/react/24/outline";
import { ListingGalleryImage } from "components/ListingImageGallery/utils/types";
import Page404 from "containers/404/Page404";
import LoadingPage from "containers/Loading/LoadingPage";
import { useListing } from "hooks/listings/useListing";
import { Listing } from "models/listings";
import { UserRole } from "models/users";
import { useAuth } from "providers/AuthProvider";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import convertImageLocationToUrl from "utils/convertImageLocationToUrl";
import { getAmenity } from "utils/getAmenity";
import getColorForListingStatus from "utils/getColorForListingStatus";
import { getListingStatus } from "utils/getListingStatus";
import DetailPagetLayout from "./Layout";

interface Props {
  listing: Listing;
}

const ListingDetailPage: FC<Props> = ({ listing }) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const thisPathname = useLocation().pathname;
  const router = useNavigate();
  const googleApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

  function canApply() {
    // todo check if user has alreday applied for this listing
    return currentUser == null || currentUser === undefined || currentUser.role === UserRole.STUDENT;
  }

  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const renderOverviewSection = () => {


    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <div className="space-x-4">
            {listing?.unitType && <Badge name={t(`listing.propertyTypes.${listing.unitType}`, listing.unitType)} />}
            {listing?.status && <Badge color={getColorForListingStatus(listing.status)} name={getListingStatus(t, listing.status)} />}
          </div>
          <div className="flex justify-between items-center">
            <span />
            {canApply() && (
              <ButtonPrimary className="ml-5" >
                <Link to={`/listing/${listing?.externalId}/apply`}>
                  <i className="las la-envelope" />
                  <span className="pl-2">
                    {t("page.listingDetail.actions.apply", "Apply")}
                  </span>
                </Link>
              </ButtonPrimary>
            )}
          </div>
        </div>

        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {listing?.title}
        </h2>

        <div className="flex items-center space-x-4">
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {listing?.address.city}, {listing?.address.district} </span>
          </span>
        </div>

        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        <div className="flex flex-col md:flex-row md:items-center xl:justify-start md:space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3">
            <i className="las la-expand-arrows-alt text-lg"></i>
            <span className=" ">
              {listing?.areaSqm} <span className="inline-block">{t("common.m2", "Sq. meters")} {listing.areaSqm !== listing.totalAreaSqm && (
                <span className="text-neutral-500 dark:text-neutral-400">({listing?.totalAreaSqm} {t("common.m2", "Sq. meters")})</span>
              )}
              </span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">
              {listing?.bedrooms} <span className="inline-block">{t("listing.bedrooms", "bedrooms")}</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bath text-2xl"></i>
            <span className=" ">
              {listing?.bathrooms} <span className="inline-block">{t("listing.bathrooms", "bedrooms")}</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-utensils text-2xl"></i>
            <span className=" ">
              {listing?.kitchens} <span className="inline-block">{t("listing.kitchens", "kitchens")}</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderDescriptionSection = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">{t("page.listingDetail.description", "Property Description")} </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>
            {listing?.description.split('\n').map((line, index) => (
              <p className="py-1" key={index}>{line}</p>
            ))}
          </span>
        </div>
      </div>
    );
  };

  const renderTenantDescriptionSection = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">{t("page.listingDetail.tenantDescription", "Tenant Description")} </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>
            {listing?.tenantDescription.split('\n').map((line, index) => (
              <p className="py-1" key={index}>{line}</p>
            ))}
          </span>
        </div>
      </div>
    );
  };

  const renderAmenitiesSection = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">{t("page.listingDetail.amenities.title", "Amenities")} </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {t(`page.listingDetail.amenities.description`, "About the property's amenities and services")}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {listing?.amenities.filter((_, i) => i < 12).map((item) => (
            <div key={item} className="flex items-center space-x-3">
              <span className=" ">{getAmenity(t, item)}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderPriceSection = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">{t("page.listingDetail.price.title", "Price breakdown")}</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 mb-4">
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>{t("listing.totalRent", "Total")}</span>
              <span>EUR {listing?.totalRent}</span>
            </div>
            <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span>{t("listing.basicRent", "Basic Rent")}</span>
              <span>EUR {listing?.basicRent}</span>
            </div>
            <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span>{t("listing.ancillaryCost", "Ancillary Costs")}</span>
              <span>EUR {listing?.ancillaryCost}</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>{t("listing.securityDeposit", "Security Deposit")}</span>
              <span>EUR {listing?.securityDeposit}</span>
            </div>
          </div>

          {/* ADDITIONAL COSTS */}
          {listing?.additionalCosts.length > 0 && (
            <>
              <div className="w-full border-b mt-2 border-neutral-200 dark:border-neutral-700"></div>

              <h5 className="text-lg font-semibold my-4">{t("page.listingDetail.price.additionalCosts", "Additional Costs")}</h5>
              <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 mb-4">
              
                {listing?.additionalCosts.map((item) => (
                  <div key={item.description} className="p-4  flex justify-between items-center space-x-4 rounded-lg">
                    <span>{item.description}</span>
                    <span>EUR {item.cost}</span>
                  </div>
                ))}
              </div>
            </>
          )}

        </div>
      </div>
    );
  };

  const renderLocationSection = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">{t("page.listingDetail.location.title", "Location")}</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {listing?.address.city}, {listing?.address.district} {listing?.address.postalCode}
          </span>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {listing?.address.street}
          </span>

        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>
            {listing?.locationDescription.split('\n').map((line, index) => (
              <p className="py-1" key={index}>{line}</p>
            ))}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            <iframe
              title="x"
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src={`https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=${listing?.address.city}+${listing?.address.district ? listing?.address.district : ""}+${listing?.address.street ? listing?.address.street : ""}`}
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="nc-ListingStayDetailPage">
      {/*  HEADER */}
      <header className="rounded-md sm:rounded-xl">
        {listing?.images?.length > 0 &&
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
              onClick={handleOpenModalImageGallery}
            >
              <img
                className="relative inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                src={convertImageLocationToUrl(listing?.images[0].location, 1200, 800)}
                alt=""
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {listing.images.length > 1 && listing.images.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                  }`}
              >
                <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                  <img
                    className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                    src={convertImageLocationToUrl(item.location, 400, 300)}
                    alt=""
                    sizes="400px"
                  />
                </div>

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={handleOpenModalImageGallery}
                />
              </div>
            ))}

            <button
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
              onClick={handleOpenModalImageGallery}
            >
              <Squares2X2Icon className="w-5 h-5" />
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                {t("page.listingDetail.actions.viewAllPhotos", "View all photos")}
              </span>
            </button>
          </div>

        }
      </header>

      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full space-y-8 lg:space-y-10 lg:pr-10">
          {renderOverviewSection()}
          {renderDescriptionSection()}
          {renderTenantDescriptionSection()}
          {renderAmenitiesSection()}
          {renderPriceSection()}
          {renderLocationSection()}
        </div>
      </main>
    </div>
  );
};

export default function ListingStayDetailPage() {
  const { id } = useParams();
  const { data, error, isLoading } = useListing(id as string);


  if (isLoading) {
    return <LoadingPage />
  }

  if (error) {
    return <Page404 />
  }

  return (
    <>
      <DetailPagetLayout images={data?.payload.images.map(
        (item, index): ListingGalleryImage => {
          return {
            id: index,
            url: item.url
          };
        })}>
        {data && <ListingDetailPage listing={data?.payload} />}
      </DetailPagetLayout></>
  );
}
