import LoadingPage from "containers/Loading/LoadingPage";
import { ActivationInformation, FinishRegistrationRequest } from "models/users";
import { FC, FormEvent, ReactElement, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { checkActivationLink, finishRegistration, resendActivationLink } from "services/user-service";
import { Alert } from "shared/Alert/Alert";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { getException } from "utils/getException";
export interface FinishRegistrationPageProps {
  className?: string;
}

type FinishRegistrationFromData = {
  email?: string;
  password?: string;
  repeatPassword?: string;
};

const FinishRegistrationPage: FC<FinishRegistrationPageProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const registrationToken = searchParams.get('token');
  const email = searchParams.get('email');
  const [errors, setErrors] = useState<{ email?: string; password?: string, repeatPassword?: string }>({});
  const navigate = useNavigate();
  const [apiError, setApiError] = useState<string | null>(null);
  const [formData, setFormData] = useState({ email: email } as FinishRegistrationFromData);
  const [activationExpired, setActivationExpired] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [activationPossible, setActivationPossible] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    checkActivation();
  }, []);

  function validateForm(): boolean {
    const newErrors: { email?: string; password?: string; repeatPassword?: string } = {};
    if (!formData.email) {
      newErrors.email = t("common.validation.email", "Email is required");

    }
    if (!formData.password) {
      newErrors.password = t("common.validation.password.required", "Password is required");
    }
    else if (formData.password.length < 8) {
      newErrors.password = t("common.validation.password", "Password must be at least 8 characters");
    }
    if (!formData.repeatPassword) {
      newErrors.repeatPassword = "Repeat password is required";
    }
    else if (formData.password !== formData.repeatPassword) {
      newErrors.repeatPassword = t("common.validation.password.match", "Passwords do not match");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    if (validateForm()) {
      setApiError(null);
      setActivationExpired(false);
      const request = {
        email: formData.email,
        password: formData.password,
        registrationToken: registrationToken,
      } as FinishRegistrationRequest;

      // Call the login function from the user-service.ts file
      finishRegistration(request).then(() => {
        navigate("/registration-finished");
      }).catch((err) => {
        setApiError(getException(t, err.response.data.error.message));
        if (err.response.data.error.message === "exception.finish-registration.registration-expired") {
          setActivationExpired(true);
        }
      });
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }

  if (!registrationToken) {
    return (
      <div className="container flex flex-col items-center justify-center h-screen">
        <h2 className="text-3xl font-semibold text-neutral-900 dark:text-neutral-100">{t("page.finishRegistration.invalidToken", "Invalid token")}</h2>
        <Link to="/login" className="mt-4 text-primary-600 hover:underline">{t("common.button.login", "Log In")}</Link>
      </div>
    );
  }

  function resendActivation(): void {
    setApiError(null);
    setMessage(null);
    resendActivationLink({ email: email, registrationToken: registrationToken } as ActivationInformation).then(() => {
      setMessage(t("page.finishRegistration.activationLinkSent", "Activation link has been sent"));
    }).catch((err) => {
      setApiError(getException(t, err.response.data.error.message));
    });
  }

  const checkActivation = (): void => {
    setApiError(null);
    setActivationPossible(null);
    setLoading(true);
    checkActivationLink({ email: email, registrationToken: registrationToken } as ActivationInformation).then((response) => {
      setActivationPossible(response.data.payload.activationPossible);
      setLoading(false);
    }).catch((err) => {
      setApiError(getException(t, err.response.data.error.message));
      setLoading(false);
    });
  }

  const renderActivationLink = (): ReactElement => {
    return (
      <div className="max-w-md mx-auto space-y-6">
        {(activationExpired || activationPossible === false) &&
          <>
            <div className="text-center space-y-4">
              <Alert type="error">
                {t("exception.finish-registration.registration-expired", "Activation link has expired")}
              </Alert>
              <ButtonPrimary onClick={resendActivation} className="w-full">
                {t("common.button.resendActivationLink", "Resend Activation Link")}
              </ButtonPrimary>
            </div>
          </>
        }

        {
          message &&
          <Alert type="info">
            {message}
          </Alert>
        }
      </div>);
  };

  if (loading) {
    return (
      <LoadingPage />
    )
  }

  return (
    <>
      <div className={`nc-FinishRegistrationPage ${className}`} data-nc-id="FinishRegistrationPage">
        <Helmet>
          <title>{t("page.finishRegistration.helmet", "Finish Registration")}</title>
        </Helmet>
        <div className="container mb-24 lg:mb-32">
          <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            <title>{t("page.finishRegistration.title", "Finish Registration")}</title>
          </h2>

          {activationPossible === false && renderActivationLink()}
          {activationPossible && (
            <>
              {/* subtitle */}
              <p className="text-lg text-neutral-500 dark:text-neutral-400 text-center mb-10">
                {t("page.finishRegistration.description", "Please provide the following information to complete your registration")}
              </p>
              <div className="max-w-md mx-auto space-y-6">
                {/* FORM */}
                <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      {t("common.field.email", "Email address")}
                    </span>
                    <Input
                      disabled={true}
                      type="email"
                      name="email"
                      value={formData.email}
                      className="mt-1"
                    />
                    {errors.email && <p className="text-red-500">{errors.email}</p>}
                  </label>
                  <label className="block">
                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                      {t("common.field.password", "Password")}
                    </span>
                    <Input type="password" className="mt-1" name="password"
                      value={formData.password}
                      onChange={handleChange} />
                    {errors.password && <p className="text-red-500">{errors.password}</p>}
                  </label>
                  <label className="block">
                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                      {t("common.field.confirmPassword", "Repeat Password")}
                    </span>
                    <Input type="password" className="mt-1" name="repeatPassword"
                      value={formData.repeatPassword}
                      onChange={handleChange} />
                    {errors.repeatPassword && <p className="text-red-500">{errors.repeatPassword}</p>}
                  </label>

                  <ButtonPrimary type="submit">{t("common.button.continue", "Continue")}</ButtonPrimary>
                </form>

                {renderActivationLink()}

              </div>
            </>
          )}
          {/* ERROR */}
          <div className="max-w-md mx-auto mt-10">
            {apiError &&
              <Alert type="error">
                {apiError}
              </Alert>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default FinishRegistrationPage;