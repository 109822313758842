

import { userRoles, userStatuses } from "data/constants";
import { useUsers } from "hooks/users/useUser";
import { SortDirection } from "models/filters";
import { AdminRegistration, Gender, User, UserFilter, UserRole, UserStatus } from "models/users";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { changeUserActiveStatus, createAdmin, deleteUser } from "services/user-service";
import { Alert } from "shared/Alert/Alert";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import FilterBar, { Filter } from "shared/Filter/FilterBar";
import PillMultiSelectFilter, { FilterOption } from "shared/Filter/PillMultiSelectFilter";
import Input from "shared/Input/Input";
import { ConfirmationModal } from "shared/Modal/ConfirmationModal";
import NcModal from "shared/NcModal/NcModal";
import Pagination from "shared/Pagination/Pagination";
import Select from "shared/Select/Select";
import { formatDateTime } from "utils/formatDate";
import getColorForUserStatus from "utils/getColorForUserStatus";
import { getException } from "utils/getException";
import { getGender } from "utils/getGender";
import { getUserRole } from "utils/getUserRole";
import { getUserStatus } from "utils/getUserStatus";
import { getBasicFilterFromSearchParams } from "utils/queryParamFilterUtils";
import UserInformationPopup from "../UserInformationPopup";

interface UserFilterSelectedOptions {
    roles: FilterOption<UserRole>[];
    statuses: FilterOption<UserStatus>[];
}

const UserList = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [changeTrigger, setChangeTrigger] = useState(0);
    const [filter, setFilter] = useState<UserFilter>({} as UserFilter);
    const [currentPage, setCurrentPage] = useState(0);
    const { data, error, isLoading } = useUsers(filter, [filter, changeTrigger]);
    const [newAdmin, setNewAdmin] = useState({ gender: Gender.MALE } as AdminRegistration);
    const [apiError, setApiError] = useState<string | null>(null);
    const [keywordAndSortingFilter, setKeywordAndSortingFilter] = useState<Filter>({} as Filter);
    const [selectedOptions, setSelectedOptions] = useState<UserFilterSelectedOptions>({ roles: [], statuses: [] } as UserFilterSelectedOptions);


    function triggerChange() {
        setChangeTrigger(changeTrigger + 1);
    }

    useEffect(() => {
        if (data) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [data]);

    useEffect(() => {
        const newFilter = getBasicFilterFromSearchParams(searchParams, "lastModified") as UserFilter;
        newFilter.roles = searchParams.getAll("roles") as UserRole[];
        newFilter.statuses = searchParams.getAll("status") as UserStatus[];
        setFilter(newFilter);
        if (newFilter.page) {
            setCurrentPage(newFilter.page);
        }
        setKeywordAndSortingFilter({
            keyword: newFilter.keyword || "",
            sortField: newFilter.sortField,
            direction: newFilter.direction
        });
        setSelectedOptions({
            roles: newFilter.roles ? userRoleOptions.filter((option) => newFilter.roles?.includes(option.value)) : [],
            statuses: newFilter.statuses ? userStatusOptions.filter((option) => newFilter.statuses?.includes(option.value)) : []
        });
    }, [searchParams]);

    const userRoleOptions = userRoles.map((role) => {
        return { label: getUserRole(t, role), value: role } as FilterOption<UserRole>;
    });

    const userStatusOptions = userStatuses.filter(status => status !== UserStatus.NEW).map((status) => {
        return { label: getUserStatus(t, status), value: status } as FilterOption<UserStatus>;
    });

    const sortOptions = [
        { label: t("user.firstName", "First Name"), value: "firstName" },
        { label: t("user.lastName", "Last Name"), value: "lastName" },
        { label: t("user.email", "Email"), value: "email" },
        { label: t("user.role", "Role"), value: "role" },
        { label: t("page.users.table.status", "Status"), value: "status" },
        { label: t("common.dateCreated", "Date Created"), value: "dateCreated" },
        { label: t("common.lastModified", "Last Modified"), value: "lastModified" }
    ];


    const handleKeywordChange = (keyword: string) => {
        const newFilter = { ...filter, keyword: keyword };
        setFilter(newFilter);
        searchParams.set("keyword", keyword);
        setSearchParams(searchParams);
    }

    const handleSortChange = (sortField: string) => {
        const newFilter = { ...filter, sort: sortField };
        setFilter(newFilter);
        searchParams.set("sortField", sortField);
        setSearchParams(searchParams);
    }

    const handleDirectionChange = (direction: SortDirection) => {
        const newFilter = { ...filter, direction: direction };
        setFilter(newFilter);
        searchParams.set("sortDirection", direction);
        setSearchParams(searchParams);
    }

    const handleUserRoleFilterChanged = (selectedOptions: FilterOption<UserRole>[]) => {
        const newFilter = { ...filter, roles: selectedOptions.map((option) => option.value) };
        setFilter(newFilter);
        searchParams.delete("roles");
        selectedOptions.forEach(option => searchParams.append("roles", option.value));
        setSearchParams(searchParams);
    }

    const handleUserStatusChanged = (selectedOptions: FilterOption<UserStatus>[]) => {
        const newFilter = { ...filter, statuses: selectedOptions.map((option) => option.value) };
        setFilter(newFilter);
        searchParams.delete("status");
        selectedOptions.forEach(option => searchParams.append("status", option.value));
        setSearchParams(searchParams);
    }


    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    function handleChange(event: ChangeEvent<HTMLInputElement>): void {
        setNewAdmin({
            ...newAdmin,
            [event.target.name]: event.target.value
        });
    }

    function handleGenderChange(gender: Gender): void {
        setNewAdmin({
            ...newAdmin,
            gender: gender
        });
    }

    const handleAdminSave = (close: Function) => {
        setApiError(null);
        createAdmin(newAdmin).then(() => {
            triggerChange();
            close();
        }).catch((error) => {
            setApiError(getException(t, error.response.data.error.message));
        });
    }

    const changeUserStatus = (user: User, active: boolean) => {
        changeUserActiveStatus(user.externalId, active).then(() => {
            triggerChange();
        }).catch((error) => {
            setApiError(getException(t, error.response.data.error.message));
        });
    }

    const handleDeleteUser = (user: User) => {
        deleteUser(user.externalId).then(() => {
            triggerChange();
        }).catch((error) => {
            setApiError(getException(t, error.response.data.error.message));
        });
    }

    const renderCreateAdministratorModalContent = (close: Function) => {
        return (
            <>
                <div className="px-4 max-w-md mx-auto pt-14">
                    {/* FORM */}
                    <div className="space-y-8">
                        {/* FORM */}
                        <form className="grid grid-cols-1 gap-6" onSubmit={() => handleAdminSave(close)}>
                            <label className="block">
                                <span className="text-neutral-800 dark:text-neutral-200">
                                    {t("user.email", "Email address")}
                                </span>
                                <Input
                                    type="email"
                                    className="mt-1"
                                    name="email"
                                    value={newAdmin.email}
                                    required={true}
                                    onChange={handleChange}
                                />
                            </label>
                            <label className="block">
                                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                    {t("user.firstName", "First Name")}
                                </span>
                                <Input
                                    type="text"
                                    name="firstName"
                                    className="mt-1"
                                    value={newAdmin.firstName}
                                    required={true}
                                    onChange={handleChange}
                                />
                            </label>
                            <label className="block">
                                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                    {t("user.lastName", "Last Name")}
                                </span>
                                <Input
                                    type="text"
                                    name="lastName"
                                    className="mt-1"
                                    required={true}
                                    value={newAdmin.lastName}
                                    onChange={handleChange}
                                />
                            </label>
                            <label className="block">
                                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                    {t("user.gender", "Gender")}
                                </span>
                                <Select
                                    name="gender"
                                    onChange={(event) => handleGenderChange(event.target.value as Gender)}
                                    value={newAdmin.gender}
                                    required={true}
                                >
                                    <option value={Gender.MALE}>{getGender(t, Gender.MALE)}</option>
                                    <option value={Gender.FEMALE}>{getGender(t, Gender.FEMALE)}</option>
                                    <option value={Gender.OTHER}>{getGender(t, Gender.OTHER)}</option>
                                </Select>
                            </label>
                            <label className="block">
                                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                    {t("user.dateOfBirth", "Date of Birth")}
                                </span>
                                <Input
                                    type="date"
                                    name="dateOfBirth"
                                    max={new Date().toISOString().split('T')[0]}
                                    className="mt-1"
                                    value={newAdmin.dateOfBirth}
                                    onChange={handleChange}
                                    required={true}
                                />
                            </label>
                            <label className="block">
                                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                    {t("user.phoneNumber", "Phone Number")}
                                </span>
                                <Input
                                    type="text"
                                    name="phoneNumber"
                                    className="mt-1"
                                    value={newAdmin.phoneNumber}
                                    required={true}
                                    onChange={handleChange}
                                />
                            </label>
                            <div className="flex justify-end space-x-5 py-12">
                                <ButtonSecondary onClick={() => close()}>{t("common.button.cancel", "Cancel")}</ButtonSecondary>
                                <ButtonPrimary type="submit" onClick={() => handleAdminSave(close)}>{t("common.button.save", "Save")}</ButtonPrimary>
                            </div>
                        </form>

                        {/* ERROR */}
                        {apiError &&
                            <Alert type="error">
                                {apiError}
                            </Alert>
                        }
                    </div>

                </div>
            </>
        )
    }

    const renderUserStatusChangeButton = (user: User): ReactNode => {
        if (user.status === UserStatus.REGISTRATION_PENDING) {
            return null;
        }
        const action = user.status === UserStatus.INACTIVE;
        const actionText = action ? t("user.actions.activate.button", 'Activate') : t("user.actions.deactivate.button", 'Deactivate');
        const title = action ? t("user.actions.activate.title", 'Activate User?') : t("user.actions.deactivate.title", 'Deactivate User?');
        const message = action ? t("user.actions.activate.description", 'Are you sure you want to activate this user?') : t("user.actions.deactivate.description", 'Are you sure you want to deactivate this user?');
        const icon = action ? 'las la-unlock' : 'las la-lock';
        const colorClass = action ? 'hover:bg-green-300 hover:dark:bg-green-800' : 'hover:bg-red-300 hover:dark:bg-red-800';
        return (
            <ConfirmationModal confirmText={actionText} cancelText={t("common.button.cancel", "Cancel")} title={title}
                message={message}
                onConfirm={() => changeUserStatus(user, user.status === UserStatus.INACTIVE)}
                onCancel={() => { }}
                renderTrigger={(openModal: Function) => {
                    return (
                        <>
                            <ButtonSecondary className={colorClass} onClick={() => openModal()}>
                                <i className={icon}></i>
                                <span className="pl-2">{actionText}</span>
                            </ButtonSecondary>
                        </>
                    )
                }
                } />
        )
    }

    const renderDeleteUser = (user: User): ReactNode => {
        const actionText = t("common.button.delete", 'Delete');
        const title = t("user.actions.delete.title", 'Delete User?');
        const message = t("user.actions.delete.description", 'Are you sure you want to delete this user?');
        const icon = 'las la-trash';
        const colorClass = 'hover:bg-red-300 hover:dark:bg-red-800';
        return (
            <ConfirmationModal confirmText={actionText} cancelText={t("common.button.cancel", "Cancel")} title={title}
                message={message}
                onConfirm={() => handleDeleteUser(user)}
                onCancel={() => { }}
                renderTrigger={(openModal: Function) => {
                    return (
                        <>
                            <ButtonSecondary className={colorClass} onClick={() => openModal()}>
                                <i className={icon}></i>
                                <span className="pl-2">{actionText}</span>
                            </ButtonSecondary>
                        </>
                    )
                }
                } />
        )
    }

    const renderSection1 = () => {
        return (
            <div className="space-y-6 sm:space-y-8">
                <Helmet>
                    <title>{t("page.users.helmet", "Users")}</title>
                </Helmet>
                <div>
                    <h2 className="text-3xl font-semibold">{t("page.users.title", "Users")}</h2>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex mt-4 ">
                    <div className="pt-2">
                        <NcModal
                            contentExtraClass="max-w-md"
                            contentPaddingClass="px-4"
                            renderTrigger={(openModal) => (
                                <ButtonPrimary
                                    onClick={() => openModal()}
                                >
                                    {t("page.users.createAdmin.button", "Create Administrator")}
                                </ButtonPrimary>
                            )}
                            renderContent={renderCreateAdministratorModalContent}
                            modalTitle={t("page.users.createAdmin.title", "Create Administrator")}
                        />
                    </div>
                </div>
                <div className="bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl p-10 mb-32">

                    <div className="space-y-8">
                        <FilterBar
                            filter={keywordAndSortingFilter}
                            sortOptions={sortOptions}
                            onDirectionChange={handleDirectionChange}
                            onKeywordChange={handleKeywordChange}
                            onSortChange={handleSortChange}
                        />
                        <div className="flex space-x-4">
                            <PillMultiSelectFilter
                                label={t("user.role", "Role")}
                                defaultSelection={selectedOptions.roles}
                                options={userRoleOptions}
                                onSelectionChange={handleUserRoleFilterChanged} />

                            <PillMultiSelectFilter
                                label={t("user.status", "Status")}
                                defaultSelection={selectedOptions.statuses}
                                options={userStatusOptions}
                                onSelectionChange={handleUserStatusChanged} />
                        </div>
                    </div>


                    <div className="overflow-x-auto">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-8">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 rounded-3xl">
                                <tr>
                                    <th scope="col" className="px-6 py-3" >{t("user.user", "User")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("user.role", "Role")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("user.status", "Status")}</th>
                                    <th scope="col" className="px-6 py-3" >{t("common.lastModified", "Last Modified")}</th>
                                    <th scope="col" className="px-6 py-3" ></th>
                                </tr>
                            </thead>

                            <tbody>
                                {data?.payload.map((item) => (
                                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                        {/* <td className="px-6 py-4" >{item.firstName}</td>
                                        <td className="px-6 py-4">{item.lastName}</td>
                                        <td className="px-6 py-4">{item.email}</td> */}
                                        <td className="px-6 py-4"><UserInformationPopup user={item} /></td>
                                        <td className="px-6 py-4">{getUserRole(t, item.role)}</td>
                                        <td className="px-6 py-4">
                                            <Badge name={getUserStatus(t, item.status)} color={getColorForUserStatus(item.status)} />
                                        </td>
                                        <td className="px-6 py-4">{formatDateTime(item.lastModified)}</td>
                                        <td className="px-6 py-4">
                                            <div className="flex space-x-4">
                                                {renderUserStatusChangeButton(item)}
                                                {renderDeleteUser(item)}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {
                        data && data.totalPages > 1 && (
                            <div className="flex mt-16 justify-center items-center">
                                <Pagination
                                    totalPages={data.totalPages}
                                    currentPage={currentPage}
                                    setCurrentPage={handlePageChange}
                                />
                            </div>
                        )
                    }
                </div>

            </div >
        );
    };

    return (
        <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900 ">
            <div className="container pt-6 pb-24 lg:pb-32">
                {renderSection1()}
            </div>
        </div>
    );
};

export default UserList;
