import { UnitType } from "models/listings";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "shared/Checkbox/Checkbox";

// DEMO DATA

export interface PropertyTypeSelectProps {
  unitTypes: UnitType[];
  onChange?: (data: UnitType[]) => void;
}

const PropertyTypeSelect: FC<PropertyTypeSelectProps> = ({
  onChange,
  unitTypes,
}) => {
  const { t } = useTranslation();
  const allUnitTypes = [UnitType.HOUSE, UnitType.APARTMENT, UnitType.ROOM, UnitType.OTHER];
  const [typeOfProperty, setTypeOfProperty] = useState<UnitType[]>(unitTypes || []);

  useEffect(() => {
    setTypeOfProperty(unitTypes);
  }, [unitTypes]);

  const handleTypeChange = (checked: boolean, item: UnitType) => {
    let copy = [...(typeOfProperty || [])];
    if (checked) {
      copy.push(item);
      setTypeOfProperty((prev) => {
        if (!prev?.includes(item)) {
          return [...(prev || []), item];
        }
        return prev;
      });
    } else {
      if (copy.includes(item)) {
        copy.splice(copy.indexOf(item), 1);
      }
      setTypeOfProperty((prev) => {
        return (prev || []).filter((i) => i !== item);
      });
    }
    if (onChange) {
      onChange(copy);
    }
  }

  return (
    <div className="p-5">
      <span className="block font-semibold text-xl sm:text-2xl">
        {t("page.search.field.propertyType.description", "Property Type")}
      </span>
      <div className="relative flex flex-col space-y-5 mt-7">
        {allUnitTypes.map((item, index) => (
          <div key={item} className="">
            <Checkbox
              onChange={checked => handleTypeChange(checked, item)}
              name={item}
              label={t(`listing.propertyTypes.${item}`, item)}
              defaultChecked={typeOfProperty?.includes(item)}

            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PropertyTypeSelect;
