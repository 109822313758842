import { TFunction } from "i18next";
import { NumericRange } from "models/filters";
import { ReactNode } from "react";

export function renderPriceRange(t: TFunction, range?: NumericRange): ReactNode {
    if (!range) {
        return t("common.any", "Any");
    }
    if (range.min === null && range.max === null) {
        return t("common.any", "Any");
    }
    if (range.min === null) {
        return `${t("common.upTo", "Up to")} ${range.max} ${t("common.eur", "€")}`;
    }
    if (range.max === null) {
        return `${t("common.from", "From")} ${range.min} ${t("common.eur", "€")}`;
    }
    return `${range.min} - ${range.max} ${t("common.eur", "€")}`;
}